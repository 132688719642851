import { isObject } from "radash";
import { useRequestURL } from "#app";

function mapSources(data) {
  const image = { url: false, alt: false, sources: [] };
  if (data && data.length > 0) {
    const sources = data[0];
    image.src = sources.png_image3x; // nuxt manage image rendering for smaller devices
  }
  return image;
}

const sectionIndexes = {
  quit: {
    name: "quit-subpage-index-hesitate-index",
    params: {},
  },
  information: {
    name: "information-subpage-index-category-index",
    params: { category: "" },
  },
  prevention: {
    name: "prevention-subpage-index-category-index",
    params: { category: "" },
  },
};

function mapCategory(category, section, posts, localePath) {
  const id = parseInt(category.id);
  const image = mapSources(category.image);

  let { name, params } = sectionIndexes[section];
  if (params.hasOwnProperty("category")) {
    params.category = category.slug;
  }

  posts = posts.map((post) => {
    let params = {};

    switch (section) {
      case "quit": {
        params = { post: post.slug };
        break;
      }
      default:
      case "information":
      case "prevention": {
        params = { category: category.slug, post: post.slug };
      }
    }

    const link = localePath({ name: `${name}-slug`, params });
    return {
      id: post.id,
      title: post.title,
      excerpt: post.excerpt,
      icon: post.icon.length > 0 ? post.icon[0].url : false,
      link,
    };
  });

  const link = localePath({ name, params });

  return {
    id,
    title: category.title,
    longTitle: category.longTitle,
    subtitle: category.subtitle,
    description: category.description,
    icon: category.icon && category.icon.length > 0 ? category.icon[0].url : false,
    link,
    slug: category.slug,
    image,
    posts,
    seo: category.seo,
  };
}

function mapPosts(post) {
  const image = mapSources(post.image);
  let tags = [];
  if (post.articleTags.length > 0) {
    tags = post.articleTags.map((t) => {
      return {
        title: t.title,
        slug: t.slug,
      };
    });
  }

  return {
    id: post.id,
    title: post.title,
    excerpt: post.excerpt,
    date: post.postDate,
    slug: post.slug,
    image,
    tags,
  };
}

function mapArticles(article, localePath) {
  return {
    id: parseInt(article.id),
    title: article.title,
    excerpt: article.excerpt,
    icon: article.icon.length > 0 ? article.icon[0].url : false,
    slug: article.slug,
    link: localePath({
      name: `quit-subpage-index-hesitate-index-slug`,
      params: {
        post: article.slug,
      },
    }),
    seo: {
      title: article.seoArticle.title,
      description: article.seoArticle.description,
    },
  };
}

function setupMeta(image, seo) {
  let isPathUrl = useRequestURL().searchParams.get('path')?.endsWith('png')
  const {
    _value: { htmlAttrs, meta, link },
  } = useLocaleHead({
    addSeoAttributes: !isPathUrl,
  });
   
  if (isPathUrl) { image = useRequestURL().searchParams.get('path') }

  const og = image
    ? { hid: "og:image", name: "og:image", content: image }
    : {
        hid: "og:image",
        name: "og:image",
        content: "/images/default-facebook.png",
      };

  if (!isObject(seo)) return {};

  useSeoMeta({
    title: seo.title,
    ogTitle: seo.title,
    description: seo.description,
    ogDescription: seo.description,
    ogImage: og.content?.src || og.content
  });

  useHead({
    htmlAttrs,
    title: seo.title,
    meta: [
      {
        hid: "description",
        name: "description",
        content: seo.description,
      },
      og,
      ...meta,
    ],
    link: [
      ...link
        .filter((l) => l.rel === "canonical")
        .map((l) => {
          return {
            rel: l.rel,
            href: l.href.replace("http://", "https://").replace(/\/$/i, ""),
          };
        }),
    ],
  });
}

export { mapArticles, mapCategory, mapPosts, mapSources, setupMeta };
