import { default as indexT0PFoHcckUMeta } from "/var/www/production/qst/pages/index.vue?macro=true";
import { default as index8Stw6NlfeMMeta } from "/var/www/production/qst/pages/information/index.vue?macro=true";
import { default as _91slug_93qY4wE6CGGiMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexVvvDN21mb9Meta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue?macro=true";
import { default as index5PaRDdybCuMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/index.vue?macro=true";
import { default as _91slug_93Np9bYZ1FXEMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue?macro=true";
import { default as indexemYedTrf6uMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue?macro=true";
import { default as newswCaoQE2HARMeta } from "/var/www/production/qst/pages/information/subpage/index/[category]/news.vue?macro=true";
import { default as index7aTgS8GpdoMeta } from "/var/www/production/qst/pages/information/subpage/index.vue?macro=true";
import { default as personal_45information_45policyJwVTNzg08CMeta } from "/var/www/production/qst/pages/personal-information-policy.vue?macro=true";
import { default as indexVBmjVD3JPzMeta } from "/var/www/production/qst/pages/prevention/index.vue?macro=true";
import { default as _91slug_938SkGCERnGIMeta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexIywIrGBzQ6Meta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexqKrq78Oiv3Meta } from "/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue?macro=true";
import { default as indexRWT3VJr98yMeta } from "/var/www/production/qst/pages/prevention/subpage/index.vue?macro=true";
import { default as privacy_45policy5fxTFg6tQnMeta } from "/var/www/production/qst/pages/privacy-policy.vue?macro=true";
import { default as acm_4520217MMeco36KGMeta } from "/var/www/production/qst/pages/quit/forms/acm-2021.vue?macro=true";
import { default as healthcarenZEYDLLpnVMeta } from "/var/www/production/qst/pages/quit/forms/healthcare.vue?macro=true";
import { default as newsletterWH2tABjFoPMeta } from "/var/www/production/qst/pages/quit/forms/newsletter.vue?macro=true";
import { default as orderoEAB3PfrAVMeta } from "/var/www/production/qst/pages/quit/forms/order.vue?macro=true";
import { default as pharmacykevim95VwIMeta } from "/var/www/production/qst/pages/quit/forms/pharmacy.vue?macro=true";
import { default as vapeobdvcClII2Meta } from "/var/www/production/qst/pages/quit/forms/vape.vue?macro=true";
import { default as indexaE8iKdx8PhMeta } from "/var/www/production/qst/pages/quit/index.vue?macro=true";
import { default as indexn9Bm4A7qYhMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/index.vue?macro=true";
import { default as personS7tv4kPOJTMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/person.vue?macro=true";
import { default as phone0NW5a9JAcKMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/phone.vue?macro=true";
import { default as smsOFye8njiMjMeta } from "/var/www/production/qst/pages/quit/subpage/index/help/sms.vue?macro=true";
import { default as confidence_results37EEmy3MtwMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js?macro=true";
import { default as confidenceEys0MvvpjSMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue?macro=true";
import { default as _91slug_937y8W71c5cBMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue?macro=true";
import { default as indexBah4YEdMGoMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue?macro=true";
import { default as _91slug_93W9rEdBt2D8Meta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue?macro=true";
import { default as indexbRUi3H91dNMeta } from "/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue?macro=true";
import { default as pro6iPF5oKDLLMeta } from "/var/www/production/qst/pages/quit/subpage/index/pro.vue?macro=true";
import { default as indexrzrdB8IgjpMeta } from "/var/www/production/qst/pages/quit/subpage/index.vue?macro=true";
import { default as responsabilityEpvnNPF9gyMeta } from "/var/www/production/qst/pages/responsability.vue?macro=true";
import { default as search8rNoNTgnI6Meta } from "/var/www/production/qst/pages/search.vue?macro=true";
import { default as sharelaX8QHWfMXMeta } from "/var/www/production/qst/pages/share.vue?macro=true";
import { default as sitemapZqwA1kh61aMeta } from "/var/www/production/qst/pages/sitemap.vue?macro=true";
import { default as component_45stubIj3KVH6pKgMeta } from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubIj3KVH6pKg } from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexT0PFoHcckUMeta?.name ?? "index___fr",
    path: indexT0PFoHcckUMeta?.path ?? "/",
    meta: indexT0PFoHcckUMeta || {},
    alias: indexT0PFoHcckUMeta?.alias || [],
    redirect: indexT0PFoHcckUMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8Stw6NlfeMMeta?.name ?? "information___fr",
    path: index8Stw6NlfeMMeta?.path ?? "/je-minforme",
    meta: index8Stw6NlfeMMeta || {},
    alias: index8Stw6NlfeMMeta?.alias || [],
    redirect: index8Stw6NlfeMMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/index.vue").then(m => m.default || m)
  },
  {
    name: index7aTgS8GpdoMeta?.name ?? "information-subpage___en",
    path: index7aTgS8GpdoMeta?.path ?? "/information/subpage",
    meta: index7aTgS8GpdoMeta || {},
    alias: index7aTgS8GpdoMeta?.alias || [],
    redirect: index7aTgS8GpdoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: index5PaRDdybCuMeta?.name ?? undefined,
    path: index5PaRDdybCuMeta?.path ?? ":category()",
    meta: index5PaRDdybCuMeta || {},
    alias: index5PaRDdybCuMeta?.alias || [],
    redirect: index5PaRDdybCuMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93qY4wE6CGGiMeta?.name ?? "information-subpage-index-category-index-slug___fr",
    path: _91slug_93qY4wE6CGGiMeta?.path ?? "/je-minforme/:post()",
    meta: _91slug_93qY4wE6CGGiMeta || {},
    alias: _91slug_93qY4wE6CGGiMeta?.alias || [],
    redirect: _91slug_93qY4wE6CGGiMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVvvDN21mb9Meta?.name ?? "information-subpage-index-category-index___fr",
    path: indexVvvDN21mb9Meta?.path ?? "/je-minforme/:category()",
    meta: indexVvvDN21mb9Meta || {},
    alias: indexVvvDN21mb9Meta?.alias || [],
    redirect: indexVvvDN21mb9Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newswCaoQE2HARMeta?.name ?? undefined,
    path: newswCaoQE2HARMeta?.path ?? ":category()/news",
    meta: newswCaoQE2HARMeta || {},
    alias: newswCaoQE2HARMeta?.alias || [],
    redirect: newswCaoQE2HARMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Np9bYZ1FXEMeta?.name ?? "information-subpage-index-category-news-slug___fr",
    path: _91slug_93Np9bYZ1FXEMeta?.path ?? "/je-minforme/actualites/:tag()/:post()",
    meta: _91slug_93Np9bYZ1FXEMeta || {},
    alias: _91slug_93Np9bYZ1FXEMeta?.alias || [],
    redirect: _91slug_93Np9bYZ1FXEMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexemYedTrf6uMeta?.name ?? "information-subpage-index-category-news___fr",
    path: indexemYedTrf6uMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: indexemYedTrf6uMeta || {},
    alias: indexemYedTrf6uMeta?.alias || [],
    redirect: indexemYedTrf6uMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: index7aTgS8GpdoMeta?.name ?? "information-subpage___fr",
    path: index7aTgS8GpdoMeta?.path ?? "/information/subpage",
    meta: index7aTgS8GpdoMeta || {},
    alias: index7aTgS8GpdoMeta?.alias || [],
    redirect: index7aTgS8GpdoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: index5PaRDdybCuMeta?.name ?? undefined,
    path: index5PaRDdybCuMeta?.path ?? "/je-minforme/:category()",
    meta: index5PaRDdybCuMeta || {},
    alias: index5PaRDdybCuMeta?.alias || [],
    redirect: index5PaRDdybCuMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93qY4wE6CGGiMeta?.name ?? "information-subpage-index-category-index-slug___fr",
    path: _91slug_93qY4wE6CGGiMeta?.path ?? ":post()",
    meta: _91slug_93qY4wE6CGGiMeta || {},
    alias: _91slug_93qY4wE6CGGiMeta?.alias || [],
    redirect: _91slug_93qY4wE6CGGiMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVvvDN21mb9Meta?.name ?? "information-subpage-index-category-index___fr",
    path: indexVvvDN21mb9Meta?.path ?? "/je-minforme/:category()",
    meta: indexVvvDN21mb9Meta || {},
    alias: indexVvvDN21mb9Meta?.alias || [],
    redirect: indexVvvDN21mb9Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newswCaoQE2HARMeta?.name ?? undefined,
    path: newswCaoQE2HARMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: newswCaoQE2HARMeta || {},
    alias: newswCaoQE2HARMeta?.alias || [],
    redirect: newswCaoQE2HARMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93Np9bYZ1FXEMeta?.name ?? "information-subpage-index-category-news-slug___fr",
    path: _91slug_93Np9bYZ1FXEMeta?.path ?? "/je-minforme/actualites/:tag()/:post()",
    meta: _91slug_93Np9bYZ1FXEMeta || {},
    alias: _91slug_93Np9bYZ1FXEMeta?.alias || [],
    redirect: _91slug_93Np9bYZ1FXEMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexemYedTrf6uMeta?.name ?? "information-subpage-index-category-news___fr",
    path: indexemYedTrf6uMeta?.path ?? "/je-minforme/actualites/:tag?",
    meta: indexemYedTrf6uMeta || {},
    alias: indexemYedTrf6uMeta?.alias || [],
    redirect: indexemYedTrf6uMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/information/subpage/index/[category]/news/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: personal_45information_45policyJwVTNzg08CMeta?.name ?? "personal-information-policy___fr",
    path: personal_45information_45policyJwVTNzg08CMeta?.path ?? "/politique-des-renseignements-personnels",
    meta: personal_45information_45policyJwVTNzg08CMeta || {},
    alias: personal_45information_45policyJwVTNzg08CMeta?.alias || [],
    redirect: personal_45information_45policyJwVTNzg08CMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/personal-information-policy.vue").then(m => m.default || m)
  },
  {
    name: personal_45information_45policyJwVTNzg08CMeta?.name ?? "personal-information-policy___en",
    path: personal_45information_45policyJwVTNzg08CMeta?.path ?? "/personal-information-policy",
    meta: personal_45information_45policyJwVTNzg08CMeta || {},
    alias: personal_45information_45policyJwVTNzg08CMeta?.alias || [],
    redirect: personal_45information_45policyJwVTNzg08CMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/personal-information-policy.vue").then(m => m.default || m)
  },
  {
    name: indexVBmjVD3JPzMeta?.name ?? "prevention___fr",
    path: indexVBmjVD3JPzMeta?.path ?? "/je-previens",
    meta: indexVBmjVD3JPzMeta || {},
    alias: indexVBmjVD3JPzMeta?.alias || [],
    redirect: indexVBmjVD3JPzMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/index.vue").then(m => m.default || m)
  },
  {
    name: indexRWT3VJr98yMeta?.name ?? "prevention-subpage___en",
    path: indexRWT3VJr98yMeta?.path ?? "/prevention/subpage",
    meta: indexRWT3VJr98yMeta || {},
    alias: indexRWT3VJr98yMeta?.alias || [],
    redirect: indexRWT3VJr98yMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexqKrq78Oiv3Meta?.name ?? undefined,
    path: indexqKrq78Oiv3Meta?.path ?? ":category()",
    meta: indexqKrq78Oiv3Meta || {},
    alias: indexqKrq78Oiv3Meta?.alias || [],
    redirect: indexqKrq78Oiv3Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_938SkGCERnGIMeta?.name ?? "prevention-subpage-index-category-index-slug___fr",
    path: _91slug_938SkGCERnGIMeta?.path ?? "/je-previens/:post()",
    meta: _91slug_938SkGCERnGIMeta || {},
    alias: _91slug_938SkGCERnGIMeta?.alias || [],
    redirect: _91slug_938SkGCERnGIMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIywIrGBzQ6Meta?.name ?? "prevention-subpage-index-category-index___fr",
    path: indexIywIrGBzQ6Meta?.path ?? "/je-previens/:category()",
    meta: indexIywIrGBzQ6Meta || {},
    alias: indexIywIrGBzQ6Meta?.alias || [],
    redirect: indexIywIrGBzQ6Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexRWT3VJr98yMeta?.name ?? "prevention-subpage___fr",
    path: indexRWT3VJr98yMeta?.path ?? "/prevention/subpage",
    meta: indexRWT3VJr98yMeta || {},
    alias: indexRWT3VJr98yMeta?.alias || [],
    redirect: indexRWT3VJr98yMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexqKrq78Oiv3Meta?.name ?? undefined,
    path: indexqKrq78Oiv3Meta?.path ?? "/je-previens/:category()",
    meta: indexqKrq78Oiv3Meta || {},
    alias: indexqKrq78Oiv3Meta?.alias || [],
    redirect: indexqKrq78Oiv3Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_938SkGCERnGIMeta?.name ?? "prevention-subpage-index-category-index-slug___fr",
    path: _91slug_938SkGCERnGIMeta?.path ?? ":post()",
    meta: _91slug_938SkGCERnGIMeta || {},
    alias: _91slug_938SkGCERnGIMeta?.alias || [],
    redirect: _91slug_938SkGCERnGIMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIywIrGBzQ6Meta?.name ?? "prevention-subpage-index-category-index___fr",
    path: indexIywIrGBzQ6Meta?.path ?? "/je-previens/:category()",
    meta: indexIywIrGBzQ6Meta || {},
    alias: indexIywIrGBzQ6Meta?.alias || [],
    redirect: indexIywIrGBzQ6Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/prevention/subpage/index/[category]/index/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: privacy_45policy5fxTFg6tQnMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policy5fxTFg6tQnMeta?.path ?? "/politique-de-confidentialite",
    meta: privacy_45policy5fxTFg6tQnMeta || {},
    alias: privacy_45policy5fxTFg6tQnMeta?.alias || [],
    redirect: privacy_45policy5fxTFg6tQnMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: acm_4520217MMeco36KGMeta?.name ?? "quit-forms-acm-2021___fr",
    path: acm_4520217MMeco36KGMeta?.path ?? "/form/acm-automne-2021",
    meta: acm_4520217MMeco36KGMeta || {},
    alias: acm_4520217MMeco36KGMeta?.alias || [],
    redirect: acm_4520217MMeco36KGMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/acm-2021.vue").then(m => m.default || m)
  },
  {
    name: acm_4520217MMeco36KGMeta?.name ?? "quit-forms-acm-2021___en",
    path: acm_4520217MMeco36KGMeta?.path ?? "/form/acm-automne-2021-en",
    meta: acm_4520217MMeco36KGMeta || {},
    alias: acm_4520217MMeco36KGMeta?.alias || [],
    redirect: acm_4520217MMeco36KGMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/acm-2021.vue").then(m => m.default || m)
  },
  {
    name: healthcarenZEYDLLpnVMeta?.name ?? "quit-forms-healthcare___fr",
    path: healthcarenZEYDLLpnVMeta?.path ?? "/jarrete/acces-professionnels/etablissements-de-sante",
    meta: healthcarenZEYDLLpnVMeta || {},
    alias: healthcarenZEYDLLpnVMeta?.alias || [],
    redirect: healthcarenZEYDLLpnVMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/healthcare.vue").then(m => m.default || m)
  },
  {
    name: healthcarenZEYDLLpnVMeta?.name ?? "quit-forms-healthcare___en",
    path: healthcarenZEYDLLpnVMeta?.path ?? "/iquitnow/access/health-facilities",
    meta: healthcarenZEYDLLpnVMeta || {},
    alias: healthcarenZEYDLLpnVMeta?.alias || [],
    redirect: healthcarenZEYDLLpnVMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/healthcare.vue").then(m => m.default || m)
  },
  {
    name: newsletterWH2tABjFoPMeta?.name ?? "quit-forms-newsletter___fr",
    path: newsletterWH2tABjFoPMeta?.path ?? "/cqts/infolettre",
    meta: newsletterWH2tABjFoPMeta || {},
    alias: newsletterWH2tABjFoPMeta?.alias || [],
    redirect: newsletterWH2tABjFoPMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/newsletter.vue").then(m => m.default || m)
  },
  {
    name: newsletterWH2tABjFoPMeta?.name ?? "quit-forms-newsletter___en",
    path: newsletterWH2tABjFoPMeta?.path ?? "/cqts/infolettre-en",
    meta: newsletterWH2tABjFoPMeta || {},
    alias: newsletterWH2tABjFoPMeta?.alias || [],
    redirect: newsletterWH2tABjFoPMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/newsletter.vue").then(m => m.default || m)
  },
  {
    name: orderoEAB3PfrAVMeta?.name ?? "quit-forms-order___fr",
    path: orderoEAB3PfrAVMeta?.path ?? "/cqts/commande-jarrete",
    meta: orderoEAB3PfrAVMeta || {},
    alias: orderoEAB3PfrAVMeta?.alias || [],
    redirect: orderoEAB3PfrAVMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/order.vue").then(m => m.default || m)
  },
  {
    name: orderoEAB3PfrAVMeta?.name ?? "quit-forms-order___en",
    path: orderoEAB3PfrAVMeta?.path ?? "/cqts/commande-jarrete-en",
    meta: orderoEAB3PfrAVMeta || {},
    alias: orderoEAB3PfrAVMeta?.alias || [],
    redirect: orderoEAB3PfrAVMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/order.vue").then(m => m.default || m)
  },
  {
    name: pharmacykevim95VwIMeta?.name ?? "quit-forms-pharmacy___fr",
    path: pharmacykevim95VwIMeta?.path ?? "/jarrete/acces-professionnels/pharmacies",
    meta: pharmacykevim95VwIMeta || {},
    alias: pharmacykevim95VwIMeta?.alias || [],
    redirect: pharmacykevim95VwIMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/pharmacy.vue").then(m => m.default || m)
  },
  {
    name: pharmacykevim95VwIMeta?.name ?? "quit-forms-pharmacy___en",
    path: pharmacykevim95VwIMeta?.path ?? "/iquitnow/access/pharmacies",
    meta: pharmacykevim95VwIMeta || {},
    alias: pharmacykevim95VwIMeta?.alias || [],
    redirect: pharmacykevim95VwIMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/pharmacy.vue").then(m => m.default || m)
  },
  {
    name: vapeobdvcClII2Meta?.name ?? "quit-forms-vape___fr",
    path: vapeobdvcClII2Meta?.path ?? "/form/envers-vape-anxiete",
    meta: vapeobdvcClII2Meta || {},
    alias: vapeobdvcClII2Meta?.alias || [],
    redirect: vapeobdvcClII2Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/vape.vue").then(m => m.default || m)
  },
  {
    name: vapeobdvcClII2Meta?.name ?? "quit-forms-vape___en",
    path: vapeobdvcClII2Meta?.path ?? "/form/envers-vape-anxiete-en",
    meta: vapeobdvcClII2Meta || {},
    alias: vapeobdvcClII2Meta?.alias || [],
    redirect: vapeobdvcClII2Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/forms/vape.vue").then(m => m.default || m)
  },
  {
    name: indexaE8iKdx8PhMeta?.name ?? "quit___fr",
    path: indexaE8iKdx8PhMeta?.path ?? "/jarrete",
    meta: indexaE8iKdx8PhMeta || {},
    alias: indexaE8iKdx8PhMeta?.alias || [],
    redirect: indexaE8iKdx8PhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/index.vue").then(m => m.default || m)
  },
  {
    name: indexaE8iKdx8PhMeta?.name ?? "quit___en",
    path: indexaE8iKdx8PhMeta?.path ?? "/iquitnow",
    meta: indexaE8iKdx8PhMeta || {},
    alias: indexaE8iKdx8PhMeta?.alias || [],
    redirect: indexaE8iKdx8PhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/index.vue").then(m => m.default || m)
  },
  {
    name: indexrzrdB8IgjpMeta?.name ?? "quit-subpage___en",
    path: indexrzrdB8IgjpMeta?.path ?? "/quit/subpage",
    meta: indexrzrdB8IgjpMeta || {},
    alias: indexrzrdB8IgjpMeta?.alias || [],
    redirect: indexrzrdB8IgjpMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexn9Bm4A7qYhMeta?.name ?? "quit-subpage-index-help___fr",
    path: indexn9Bm4A7qYhMeta?.path ?? "/jarrete/jai-besoin-daide",
    meta: indexn9Bm4A7qYhMeta || {},
    alias: indexn9Bm4A7qYhMeta?.alias || [],
    redirect: indexn9Bm4A7qYhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexn9Bm4A7qYhMeta?.name ?? "quit-subpage-index-help___en",
    path: indexn9Bm4A7qYhMeta?.path ?? "/iquitnow/i-need-help",
    meta: indexn9Bm4A7qYhMeta || {},
    alias: indexn9Bm4A7qYhMeta?.alias || [],
    redirect: indexn9Bm4A7qYhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: personS7tv4kPOJTMeta?.name ?? "quit-subpage-index-help-person___fr",
    path: personS7tv4kPOJTMeta?.path ?? "/jarrete/aide-personne",
    meta: personS7tv4kPOJTMeta || {},
    alias: personS7tv4kPOJTMeta?.alias || [],
    redirect: personS7tv4kPOJTMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: personS7tv4kPOJTMeta?.name ?? "quit-subpage-index-help-person___en",
    path: personS7tv4kPOJTMeta?.path ?? "/iquitnow/person-help",
    meta: personS7tv4kPOJTMeta || {},
    alias: personS7tv4kPOJTMeta?.alias || [],
    redirect: personS7tv4kPOJTMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: phone0NW5a9JAcKMeta?.name ?? "quit-subpage-index-help-phone___fr",
    path: phone0NW5a9JAcKMeta?.path ?? "/jarrete/aide-telephone",
    meta: phone0NW5a9JAcKMeta || {},
    alias: phone0NW5a9JAcKMeta?.alias || [],
    redirect: phone0NW5a9JAcKMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: phone0NW5a9JAcKMeta?.name ?? "quit-subpage-index-help-phone___en",
    path: phone0NW5a9JAcKMeta?.path ?? "/iquitnow/phone-help",
    meta: phone0NW5a9JAcKMeta || {},
    alias: phone0NW5a9JAcKMeta?.alias || [],
    redirect: phone0NW5a9JAcKMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: smsOFye8njiMjMeta?.name ?? "quit-subpage-index-help-sms___fr",
    path: smsOFye8njiMjMeta?.path ?? "/jarrete/aide-texto",
    meta: smsOFye8njiMjMeta || {},
    alias: smsOFye8njiMjMeta?.alias || [],
    redirect: smsOFye8njiMjMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: smsOFye8njiMjMeta?.name ?? "quit-subpage-index-help-sms___en",
    path: smsOFye8njiMjMeta?.path ?? "/iquitnow/text-help",
    meta: smsOFye8njiMjMeta || {},
    alias: smsOFye8njiMjMeta?.alias || [],
    redirect: smsOFye8njiMjMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: confidence_results37EEmy3MtwMeta?.name ?? "quit-subpage-index-hesitate-confidence_results___en",
    path: confidence_results37EEmy3MtwMeta?.path ?? "hesitate/confidence_results",
    meta: confidence_results37EEmy3MtwMeta || {},
    alias: confidence_results37EEmy3MtwMeta?.alias || [],
    redirect: confidence_results37EEmy3MtwMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js").then(m => m.default || m)
  },
  {
    name: confidenceEys0MvvpjSMeta?.name ?? "quit-subpage-index-hesitate-confidence___fr",
    path: confidenceEys0MvvpjSMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    meta: confidenceEys0MvvpjSMeta || {},
    alias: confidenceEys0MvvpjSMeta?.alias || [],
    redirect: confidenceEys0MvvpjSMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: confidenceEys0MvvpjSMeta?.name ?? "quit-subpage-index-hesitate-confidence___en",
    path: confidenceEys0MvvpjSMeta?.path ?? "/iquitnow/getting-ready-to-quit/your-confidence",
    meta: confidenceEys0MvvpjSMeta || {},
    alias: confidenceEys0MvvpjSMeta?.alias || [],
    redirect: confidenceEys0MvvpjSMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937y8W71c5cBMeta?.name ?? "quit-subpage-index-hesitate-index-slug___fr",
    path: _91slug_937y8W71c5cBMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/:post()",
    meta: _91slug_937y8W71c5cBMeta || {},
    alias: _91slug_937y8W71c5cBMeta?.alias || [],
    redirect: _91slug_937y8W71c5cBMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937y8W71c5cBMeta?.name ?? "quit-subpage-index-hesitate-index-slug___en",
    path: _91slug_937y8W71c5cBMeta?.path ?? "/iquitnow/getting-ready-to-quit/:post()",
    meta: _91slug_937y8W71c5cBMeta || {},
    alias: _91slug_937y8W71c5cBMeta?.alias || [],
    redirect: _91slug_937y8W71c5cBMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBah4YEdMGoMeta?.name ?? "quit-subpage-index-hesitate-index___fr",
    path: indexBah4YEdMGoMeta?.path ?? "/jarrete/je-me-prepare-a-arreter",
    meta: indexBah4YEdMGoMeta || {},
    alias: indexBah4YEdMGoMeta?.alias || [],
    redirect: indexBah4YEdMGoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexBah4YEdMGoMeta?.name ?? "quit-subpage-index-hesitate-index___en",
    path: indexBah4YEdMGoMeta?.path ?? "/iquitnow/getting-ready-to-quit",
    meta: indexBah4YEdMGoMeta || {},
    alias: indexBah4YEdMGoMeta?.alias || [],
    redirect: indexBah4YEdMGoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W9rEdBt2D8Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: _91slug_93W9rEdBt2D8Meta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    meta: _91slug_93W9rEdBt2D8Meta || {},
    alias: _91slug_93W9rEdBt2D8Meta?.alias || [],
    redirect: _91slug_93W9rEdBt2D8Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W9rEdBt2D8Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___en",
    path: _91slug_93W9rEdBt2D8Meta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    meta: _91slug_93W9rEdBt2D8Meta || {},
    alias: _91slug_93W9rEdBt2D8Meta?.alias || [],
    redirect: _91slug_93W9rEdBt2D8Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbRUi3H91dNMeta?.name ?? "quit-subpage-index-hesitate-obstacles___fr",
    path: indexbRUi3H91dNMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    meta: indexbRUi3H91dNMeta || {},
    alias: indexbRUi3H91dNMeta?.alias || [],
    redirect: indexbRUi3H91dNMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: indexbRUi3H91dNMeta?.name ?? "quit-subpage-index-hesitate-obstacles___en",
    path: indexbRUi3H91dNMeta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    meta: indexbRUi3H91dNMeta || {},
    alias: indexbRUi3H91dNMeta?.alias || [],
    redirect: indexbRUi3H91dNMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: pro6iPF5oKDLLMeta?.name ?? "quit-subpage-index-pro___fr",
    path: pro6iPF5oKDLLMeta?.path ?? "/jarrete/acces-professionnels",
    meta: pro6iPF5oKDLLMeta || {},
    alias: pro6iPF5oKDLLMeta?.alias || [],
    redirect: pro6iPF5oKDLLMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  },
  {
    name: pro6iPF5oKDLLMeta?.name ?? "quit-subpage-index-pro___en",
    path: pro6iPF5oKDLLMeta?.path ?? "/iquitnow/access-healthcare",
    meta: pro6iPF5oKDLLMeta || {},
    alias: pro6iPF5oKDLLMeta?.alias || [],
    redirect: pro6iPF5oKDLLMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexrzrdB8IgjpMeta?.name ?? "quit-subpage___fr",
    path: indexrzrdB8IgjpMeta?.path ?? "/quit/subpage",
    meta: indexrzrdB8IgjpMeta || {},
    alias: indexrzrdB8IgjpMeta?.alias || [],
    redirect: indexrzrdB8IgjpMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexn9Bm4A7qYhMeta?.name ?? "quit-subpage-index-help___fr",
    path: indexn9Bm4A7qYhMeta?.path ?? "/jarrete/jai-besoin-daide",
    meta: indexn9Bm4A7qYhMeta || {},
    alias: indexn9Bm4A7qYhMeta?.alias || [],
    redirect: indexn9Bm4A7qYhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexn9Bm4A7qYhMeta?.name ?? "quit-subpage-index-help___en",
    path: indexn9Bm4A7qYhMeta?.path ?? "/iquitnow/i-need-help",
    meta: indexn9Bm4A7qYhMeta || {},
    alias: indexn9Bm4A7qYhMeta?.alias || [],
    redirect: indexn9Bm4A7qYhMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/index.vue").then(m => m.default || m)
  },
  {
    name: personS7tv4kPOJTMeta?.name ?? "quit-subpage-index-help-person___fr",
    path: personS7tv4kPOJTMeta?.path ?? "/jarrete/aide-personne",
    meta: personS7tv4kPOJTMeta || {},
    alias: personS7tv4kPOJTMeta?.alias || [],
    redirect: personS7tv4kPOJTMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: personS7tv4kPOJTMeta?.name ?? "quit-subpage-index-help-person___en",
    path: personS7tv4kPOJTMeta?.path ?? "/iquitnow/person-help",
    meta: personS7tv4kPOJTMeta || {},
    alias: personS7tv4kPOJTMeta?.alias || [],
    redirect: personS7tv4kPOJTMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/person.vue").then(m => m.default || m)
  },
  {
    name: phone0NW5a9JAcKMeta?.name ?? "quit-subpage-index-help-phone___fr",
    path: phone0NW5a9JAcKMeta?.path ?? "/jarrete/aide-telephone",
    meta: phone0NW5a9JAcKMeta || {},
    alias: phone0NW5a9JAcKMeta?.alias || [],
    redirect: phone0NW5a9JAcKMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: phone0NW5a9JAcKMeta?.name ?? "quit-subpage-index-help-phone___en",
    path: phone0NW5a9JAcKMeta?.path ?? "/iquitnow/phone-help",
    meta: phone0NW5a9JAcKMeta || {},
    alias: phone0NW5a9JAcKMeta?.alias || [],
    redirect: phone0NW5a9JAcKMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/phone.vue").then(m => m.default || m)
  },
  {
    name: smsOFye8njiMjMeta?.name ?? "quit-subpage-index-help-sms___fr",
    path: smsOFye8njiMjMeta?.path ?? "/jarrete/aide-texto",
    meta: smsOFye8njiMjMeta || {},
    alias: smsOFye8njiMjMeta?.alias || [],
    redirect: smsOFye8njiMjMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: smsOFye8njiMjMeta?.name ?? "quit-subpage-index-help-sms___en",
    path: smsOFye8njiMjMeta?.path ?? "/iquitnow/text-help",
    meta: smsOFye8njiMjMeta || {},
    alias: smsOFye8njiMjMeta?.alias || [],
    redirect: smsOFye8njiMjMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/help/sms.vue").then(m => m.default || m)
  },
  {
    name: confidence_results37EEmy3MtwMeta?.name ?? "quit-subpage-index-hesitate-confidence_results___fr",
    path: confidence_results37EEmy3MtwMeta?.path ?? "hesitate/confidence_results",
    meta: confidence_results37EEmy3MtwMeta || {},
    alias: confidence_results37EEmy3MtwMeta?.alias || [],
    redirect: confidence_results37EEmy3MtwMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence_results.js").then(m => m.default || m)
  },
  {
    name: confidenceEys0MvvpjSMeta?.name ?? "quit-subpage-index-hesitate-confidence___fr",
    path: confidenceEys0MvvpjSMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    meta: confidenceEys0MvvpjSMeta || {},
    alias: confidenceEys0MvvpjSMeta?.alias || [],
    redirect: confidenceEys0MvvpjSMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: confidenceEys0MvvpjSMeta?.name ?? "quit-subpage-index-hesitate-confidence___en",
    path: confidenceEys0MvvpjSMeta?.path ?? "/iquitnow/getting-ready-to-quit/your-confidence",
    meta: confidenceEys0MvvpjSMeta || {},
    alias: confidenceEys0MvvpjSMeta?.alias || [],
    redirect: confidenceEys0MvvpjSMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/confidence.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937y8W71c5cBMeta?.name ?? "quit-subpage-index-hesitate-index-slug___fr",
    path: _91slug_937y8W71c5cBMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/:post()",
    meta: _91slug_937y8W71c5cBMeta || {},
    alias: _91slug_937y8W71c5cBMeta?.alias || [],
    redirect: _91slug_937y8W71c5cBMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937y8W71c5cBMeta?.name ?? "quit-subpage-index-hesitate-index-slug___en",
    path: _91slug_937y8W71c5cBMeta?.path ?? "/iquitnow/getting-ready-to-quit/:post()",
    meta: _91slug_937y8W71c5cBMeta || {},
    alias: _91slug_937y8W71c5cBMeta?.alias || [],
    redirect: _91slug_937y8W71c5cBMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBah4YEdMGoMeta?.name ?? "quit-subpage-index-hesitate-index___fr",
    path: indexBah4YEdMGoMeta?.path ?? "/jarrete/je-me-prepare-a-arreter",
    meta: indexBah4YEdMGoMeta || {},
    alias: indexBah4YEdMGoMeta?.alias || [],
    redirect: indexBah4YEdMGoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexBah4YEdMGoMeta?.name ?? "quit-subpage-index-hesitate-index___en",
    path: indexBah4YEdMGoMeta?.path ?? "/iquitnow/getting-ready-to-quit",
    meta: indexBah4YEdMGoMeta || {},
    alias: indexBah4YEdMGoMeta?.alias || [],
    redirect: indexBah4YEdMGoMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W9rEdBt2D8Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: _91slug_93W9rEdBt2D8Meta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    meta: _91slug_93W9rEdBt2D8Meta || {},
    alias: _91slug_93W9rEdBt2D8Meta?.alias || [],
    redirect: _91slug_93W9rEdBt2D8Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W9rEdBt2D8Meta?.name ?? "quit-subpage-index-hesitate-obstacles-slug___en",
    path: _91slug_93W9rEdBt2D8Meta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    meta: _91slug_93W9rEdBt2D8Meta || {},
    alias: _91slug_93W9rEdBt2D8Meta?.alias || [],
    redirect: _91slug_93W9rEdBt2D8Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbRUi3H91dNMeta?.name ?? "quit-subpage-index-hesitate-obstacles___fr",
    path: indexbRUi3H91dNMeta?.path ?? "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    meta: indexbRUi3H91dNMeta || {},
    alias: indexbRUi3H91dNMeta?.alias || [],
    redirect: indexbRUi3H91dNMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: indexbRUi3H91dNMeta?.name ?? "quit-subpage-index-hesitate-obstacles___en",
    path: indexbRUi3H91dNMeta?.path ?? "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    meta: indexbRUi3H91dNMeta || {},
    alias: indexbRUi3H91dNMeta?.alias || [],
    redirect: indexbRUi3H91dNMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue").then(m => m.default || m)
  },
  {
    name: pro6iPF5oKDLLMeta?.name ?? "quit-subpage-index-pro___fr",
    path: pro6iPF5oKDLLMeta?.path ?? "/jarrete/acces-professionnels",
    meta: pro6iPF5oKDLLMeta || {},
    alias: pro6iPF5oKDLLMeta?.alias || [],
    redirect: pro6iPF5oKDLLMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  },
  {
    name: pro6iPF5oKDLLMeta?.name ?? "quit-subpage-index-pro___en",
    path: pro6iPF5oKDLLMeta?.path ?? "/iquitnow/access-healthcare",
    meta: pro6iPF5oKDLLMeta || {},
    alias: pro6iPF5oKDLLMeta?.alias || [],
    redirect: pro6iPF5oKDLLMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/quit/subpage/index/pro.vue").then(m => m.default || m)
  }
]
  },
  {
    name: responsabilityEpvnNPF9gyMeta?.name ?? "responsability___fr",
    path: responsabilityEpvnNPF9gyMeta?.path ?? "/conditions-utilisation",
    meta: responsabilityEpvnNPF9gyMeta || {},
    alias: responsabilityEpvnNPF9gyMeta?.alias || [],
    redirect: responsabilityEpvnNPF9gyMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/responsability.vue").then(m => m.default || m)
  },
  {
    name: search8rNoNTgnI6Meta?.name ?? "search___fr",
    path: search8rNoNTgnI6Meta?.path ?? "/recherche",
    meta: search8rNoNTgnI6Meta || {},
    alias: search8rNoNTgnI6Meta?.alias || [],
    redirect: search8rNoNTgnI6Meta?.redirect,
    component: () => import("/var/www/production/qst/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sharelaX8QHWfMXMeta?.name ?? "share___fr",
    path: sharelaX8QHWfMXMeta?.path ?? "/progression",
    meta: sharelaX8QHWfMXMeta || {},
    alias: sharelaX8QHWfMXMeta?.alias || [],
    redirect: sharelaX8QHWfMXMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/share.vue").then(m => m.default || m)
  },
  {
    name: sharelaX8QHWfMXMeta?.name ?? "share___en",
    path: sharelaX8QHWfMXMeta?.path ?? "/progress",
    meta: sharelaX8QHWfMXMeta || {},
    alias: sharelaX8QHWfMXMeta?.alias || [],
    redirect: sharelaX8QHWfMXMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/share.vue").then(m => m.default || m)
  },
  {
    name: sitemapZqwA1kh61aMeta?.name ?? "sitemap___fr",
    path: sitemapZqwA1kh61aMeta?.path ?? "/plan-du-site",
    meta: sitemapZqwA1kh61aMeta || {},
    alias: sitemapZqwA1kh61aMeta?.alias || [],
    redirect: sitemapZqwA1kh61aMeta?.redirect,
    component: () => import("/var/www/production/qst/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/semaine-pour-un-quebec-sans-tabac-2024",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/avis-non-responsabilite",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/form/formulaire-2",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/form/f",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/acces-professionnels/pharmacie$",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/form/patient-reference-form-from-hosp",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/access/healthcare",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/form/patient-reference-form-from-phar",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/access/pharmacy",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/access$",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/my-plan/exercices",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/member/password/reset",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/access-pharmacy",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/your-confidence",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/afraid-withdrawal",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/afraid-withdrawal",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/life-boring",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/life-boring",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/stop-without-fattening",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/stop-without-fattening",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblock/health-benefits",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/iquitnow/still-hesitating/mental-roadblocks/health-benefits",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/poursuites-judiciaires/recours-collectifs-quebecois-un-nouveau-chapitre-en",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/promotion-des-produits-de-vapotage-le-gouvernement",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/levons-notre-verre-la-2e-saison-des-terrasses-sans-fumee",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/lancement-de-la-strategie-pour-un-quebec-sans-tabac-2020",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/cinq-raisons-meconnues-de-hausser-la-taxe-sur-les",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/lois-et-reglements/adoption-du-projet-de-loi-s-5-la-cigarette-electronique",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/arret-tabagique/lecole-secondaire-du-rocher-adopte-la-toute-premiere$",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/arret-tabagique/le-secret-du-controle-de-soi-comportement-sante-et$",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/dangers-sante-cigarette",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/dependance/bienfaits-arretert",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/formes-tabac",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/industrie-tabac/stratégies-marketing-cigarettiers",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/produits-de-vapotage/lois-et-indusrie",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/produits-de-vapotage-et-cigarette-electronique/cest-quoi",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-previens/ecoles-organismes-jeunesse",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-previens/maison",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/deni",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/?profil=adulte",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jeminforme/industrie-tabac;",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarete/aide-personne",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/vos-envies",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/votre-confiance",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/arreter-de-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/arreter-de-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/benefices-sante",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/benefices-sante",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/craindre-sevrage",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/craindre-sevrage",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/dangers-cigarette",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/dangers-cigarette",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/grand-pere",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/grand-pere",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/panique-cesser-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/panique-cesser-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensee-obstacle/trucs-cesser-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/jarrete/hesite-encore/pensees-obstacles/trucs-cesser-fumer",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2017",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/je-minforme/tabas-toutes-formes/types-fumees-tabac",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/cqts/:pathMatch(.*)",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  },
  {
    name: component_45stubIj3KVH6pKgMeta?.name ?? undefined,
    path: component_45stubIj3KVH6pKgMeta?.path ?? "/node/:pathMatch(.*)",
    meta: component_45stubIj3KVH6pKgMeta || {},
    alias: component_45stubIj3KVH6pKgMeta?.alias || [],
    redirect: component_45stubIj3KVH6pKgMeta?.redirect,
    component: component_45stubIj3KVH6pKg
  }
]