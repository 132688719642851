import revive_payload_client_4sVQNw7RlN from "/var/www/production/qst/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/production/qst/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/production/qst/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/production/qst/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/production/qst/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/production/qst/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/var/www/production/qst/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/production/qst/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/production/qst/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gtm_client_KEcT5dOoKs from "/var/www/production/qst/plugins/gtm.client.js";
import mitt_ZNXaeqsgM5 from "/var/www/production/qst/plugins/mitt.js";
import vuex_owYp5qnaH8 from "/var/www/production/qst/plugins/vuex.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  gtm_client_KEcT5dOoKs,
  mitt_ZNXaeqsgM5,
  vuex_owYp5qnaH8
]